<template>
  <div>
    <base-header type="secondary" class="pb-6 pb-8 pt-2 pt-md-7">
      <h2 class="d-none">{{ $t("habitList.filter") }}</h2>

      <div class="row d-none">
        <div class="col-12 col-md-3 pt-2">
          <base-input
            :placeholder="$t('habitList.filters.text')"
            v-model="name"
          >
          </base-input>
        </div>
        <div class="col-12 col-md-4 pt-2">
          <multiselect
            v-model="selectedStatus"
            label="label"
            :placeholder="$t('habitList.filters.filterByStatus')"
            track-by="code"
            open-direction="bottom"
            :options="status"
            :multiple="true"
            :searchable="false"
            :clear-on-select="false"
            :close-on-select="false"
            :hide-selected="false"
          >
            <template slot="tag" slot-scope="{ option, remove }"
              ><span class="custom__tag"
                ><span>{{ option.label }}</span
                ><span class="custom__remove" @click="remove(option)"
                  >X</span
                ></span
              ></template
            >
          </multiselect>
        </div>
        <div class="col-12 col-md-3 pt-2">
          <div class="form-group input-group date-picker">
            <date-range-picker
              v-model="dateRange"
              :options="dateOptions"
              className="form-control"
            />
          </div>
        </div>
        <div class="col-12 col-md-2 pt-2">
          <base-button type="default-button" @click="search()">{{
            $t("habitList.filters.search")
          }}</base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <card header-classes="bg_transparent" body-classes="d-flex">
            <div slot="header" class="row align-items-center">
              <div class="col-md-6">
                <h5 class="h3 mb-0">{{ $t("habitList.title") }}</h5>
              </div>
              <div class="offset-md-2 col-md-4 text-right">
                <base-button
                  v-on:click="showHabits"
                  :type="habitsClass"
                  size="sm"
                  >{{ $t("habitList.habits") }}</base-button
                >
              </div>
            </div>
            <div class="container" v-if="habitsTab">
              <div class="row">
                <div class="col-12">
                  <table class="habit-table table-bordered">
                    <thead>
                      <tr>
                        <th>{{ $t("habitList.table.request") }}</th>
                        <th>{{ $t("habitList.table.name") }}</th>
                        <th>{{ $t("habitList.table.date") }}</th>
                        <th class="text-center">{{ $t("habitList.table.actions") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(habit, index) in habits" :key="index">
                        <td class="name-habit-default text-center">
                          {{ habit.totalUsers }}
                        </td>
                        <td class="user-name">{{ habit.name }}</td>
                        <td class="created-date-default text-muted">
                          {{ formatDate(habit.createdAt) }}
                        </td>
                        <td class="text-center">
                          <div v-if="habit.status == 0">
                            <button
                              type="button"
                              class="btn btn-disapprove btn-sm"
                              @click="disapproveHabit(habit._id)"
                            >
                              {{ $t("habitList.actions.deny") }}
                            </button>
                            <button
                              type="button"
                              class="btn btn-approve btn-sm"
                              @click="approveHabit(habit._id)"
                            >
                              {{ $t("habitList.actions.addMaster") }}
                            </button>
                          </div>

                          <div v-if="habit.status == 1">
                            <button
                              type="button"
                              class="btn btn-secondary btn-sm"
                              @click="deleteHabit(habit._id)"
                            >
                              {{ $t("habitList.actions.remove") }}
                            </button>
                            <span class="badge badge-approve mx-2">
                              <i class="fa fa-check"></i>
                            </span>
                            <button
                              type="button"
                              class="btn btn-primary btn-sm"
                              @click="editHabit(habit)"
                            >
                              {{ $t("habitList.actions.editHabit") }}
                            </button>
                          </div>

                          <div v-if="habit.status == 2">
                            <span
                              class="badge badge-light text-danger mr-3"
                              @click="disapproveHabit(habit._id)"
                            >
                              {{ $t("habitList.actions.denied") }}
                            </span>
                            <button
                              type="button"
                              class="btn btn-primary btn-sm"
                              @click="editHabit(habit)"
                            >
                              {{ $t("habitList.actions.editHabit") }}
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="container" v-if="editHabitTab">
                <div class="row">
                    <div class="col-12">
                        <h2>Edit Habit</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="row">
                            <div class="col-12">
                                <h3>{{ $t("habitList.edit.name") }}</h3>
                            </div>
                            <div class="col-12">
                                <input v-model="habitToEdit.name" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="row">
                            <div class="col-12">
                                <h3>{{ $t("habitList.edit.icon") }}</h3>
                            </div>
                            <div class="col-12">
                                <input v-model="habitToEdit.icon" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="row">
                            <div class="col-12">
                                <h3>{{ $t("habitList.edit.status") }}</h3>
                            </div>
                            <div class="col-12">
                                <multiselect
                                    label="label"
                                    v-model="habitToEdit.status"
                                    :placeholder="$t('habitList.edit.status')"
                                    track-by="code"
                                    open-direction="bottom"
                                    return="code"
                                    :options="statusList"
                                    :searchable="true"
                                >
                                </multiselect>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="offset-md-6 col-md-3">
                        <base-button class="form-control" 
                            type="primary"
                            @click="showHabits">
                            {{ $t('habitList.edit.cancel') }}
                        </base-button>
                    </div>
                    <div class="col-md-3">
                        <base-button class="form-control" 
                            type="primary"
                            @click="save">
                            {{ $t('habitList.edit.save') }}
                        </base-button>
                    </div>
                </div>
            </div>

            <div slot="footer" class="align-items-right" style="padding: 0">
                <b-pagination
                    class="no-border"
                    v-model="currentPage"
                    :total-rows="totalHabitsFiltered"
                    :per-page="perPage"
                    align="right"
                    style="padding: 0"
                    @input="updatePage()"
                    hide-goto-end-buttons
                >
                </b-pagination>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import moment from "moment";
import langs from "../store/modules/langs.js";
import { required } from 'vuelidate/lib/validators'

export default {
  methods: {
    search: function () {
      let filters = [];

      if (this.selectedStatus && this.selectedStatus.length) {
        for (let i = 0, total = this.selectedStatus.length; i < total; i++) {
          filters.push("status[]=" + Number(this.selectedStatus[i].code));
        }
      }

      if (this.dateRange.length) {
        filters.push(
          "from=" + moment(this.dateRange[0], "DD/MM/YYYY").format("YYYY-MM-DD")
        );
        filters.push(
          "to=" + moment(this.dateRange[1], "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }

      if (this.name.trim() !== "") {
        filters.push("name=" + this.name.trim());
      }

      filters.push("page=" + this.currentPage);

      this.$store.dispatch("getHabitList", filters);
      this.$store.dispatch("getCountVideos", filters);
    },
    updatePage: function () {
      this.search();
    },
    formatDate(date) {
        let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    },
    save: async function() {
        this.$v.$touch()

        if (this.$v.$invalid) {
            this.$alert(
                'All the fields are mandatory',
                'Warning',
                'warning')
        } else {

            await this.$store
                .dispatch('editHabit', this.habitToEdit)
                .then(() => {
                    this.search();
                })
                .catch((error) => {
                    this.$alert(
                        "Something went wrong, Please try again",
                        "Warning",
                        "warning"
                    );
                    return Promise.reject(error.response);
                });
        
            this.showHabits()
        }        
    },
    showHabits: function() {
      this.editHabitClass = "secondary";
      this.habitsClass = "default-button";
      this.habitsTab = true;
      this.editHabitTab = false;
      this.habitToEdit = {};
    },
    editHabit: function (habit) {
      this.habitToEdit = {
          id: habit._id,
          name: habit.name,
          icon: habit.icon,
          status: habit.status
      };

      this.editHabitClass = "default-button";
      this.habitsClass = "secondary";
      this.habitsTab = false;
      this.editHabitTab = true;
      this.selStatus()
    },
    deleteHabit: async function (id) {
        try {
            this.$confirm('Do you want to delete this Habit?')
                .then(() => {
                    this.$store.dispatch("removeHabit", id)
                        .then(() => {
                            this.search()
                        })
                })
        } catch (error) {
            this.$alert(
                'Something went wrong, Please try again',
                'Warning',
                'warning')
        }      
    },
    approveHabit: async function (idHabit) {
      await this.$store
        .dispatch("approveHabit", idHabit)
        .then(() => {
          this.search();
          this.$alert("Habit approved");
        })
        .catch((error) => {
          this.$alert(
            "Something went wrong, Please try again",
            "Warning",
            "warning"
          );
          return Promise.reject(error.response);
        });
    },
    disapproveHabit: async function (idHabit) {
      await this.$store
        .dispatch("disapproveHabit", idHabit)
        .then(() => {
          this.search();
          this.$alert("Habit deny");
        })
        .catch((error) => {
          this.$alert(
            "Something went wrong, Please try again",
            "Warning",
            "warning"
          );
          return Promise.reject(error.response);
        });
    },
    selStatus: function() {
        this.statusList.forEach(status => {
            if (status.code == this.habitToEdit.status) 
                this.habitToEdit.status = status
        })
    }
  },
  validations: {
    habitToEdit: {
        name: {
            required
        },
        icon: {
            required
        }
    }
  },
  mounted() {
    this.search();
  },
  computed: {
    habits() {
      return this.$store.state.habits;
    },
    languages() {
      let languages = [];
      languages.push({
        value: "",
        text: "Language",
      });
      for (let language in langs) {
        languages.push({
          value: language,
          text: langs[language].name,
        });
      }

      return languages;
    },
  },
  components: {
    Multiselect,
  },
  data: function () {
    return {
      habitsClass: 'default-button',
      editHabitClass: 'secondary',
      habitsTab: true,
      editHabitTab: false,
      name: "",
      totalHabitsFiltered: 0,
      totalHabits: 0,
      habitToEdit: {},
      dateRange: [],
      selectedStatus: null,
      status: [
        {"code": "0", "label": "Waiting for review"},
        {"code": "1", "label": "Active"},
        {"code": "2", "label": "Rejected"},
      ],
      statusList: [
        { 'code': '0', 'label': 'All' },
        { 'code': '1', 'label': 'Add to master' },
        { 'code': '2', 'label': 'Deny' }                
      ],
      dateOptions: {
        startDate: "2019-07-25",
        maxDate: moment().toDate(),
        locale: {
          format: "YYYY-MM-DD",
        },
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment()],
          "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        },
      },
      currentPage: 1,
      perPage: 20,
      languageSelected: "",
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.custom__tag {
  display: inline-block;
  background-color: #285150;
  padding: 5px 3px;
  color: white;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 0.375rem;
}

.custom__remove {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  color: rgb(244, 121, 110);
  font-weight: bold;
}

.habit-table {
  width: 100%;
  margin-left: 1px;
  margin-right: 1px;
  float: none;
}

thead tr {
  background-color: #7a92aa;
}

tbody tr:hover {
  background: rgba(122, 146, 170, .40)
}

/* tbody tr:nth-child(even){
    background-color: rgb(211, 227, 240)
  } */

td {
  text-align: left;
  padding-left: 2%;
  padding-top: 3px;
  padding-bottom: 3px;
}

th {
  color: white;
  text-align: left;
  padding-left: 2%;
  padding-top: 4px;
  padding-bottom: 4px;
}

td.name-video-default {
  width: 37%;
}

td.user-name {
  width: 13%;
}

td.created-date-default {
  width: 23%;
}

td.language-default {
  width: 10%;
}

td.status {
  width: 15%;
}

td.name-video {
  width: 50%;
}

td.created-date {
  width: 30%;
}

td.language {
  width: 20%;
}

.no-border {
  border-style: none !important;
}

.status-text {
  color: #f85d26;
}

.reject {
  padding-top: 12px;
  padding-bottom: 4px;
}

.modal-button {
  padding-top: 1%;
  padding-bottom: 1%;
}

.approve {
  padding-top: 12px;
}

.auto-approve {
  padding-top: 10px;
  padding-bottom: 10px;
}

.reward-aht {
  padding-top: 30px;
}

.upload-date {
  padding-bottom: 8px;
}

.language-select {
  padding-top: 10px;
  padding-bottom: 10px;
}

.upload-default-video {
  padding-top: 10px;
  width: 100%;
  text-align: center;
}

.upload-default-video i.fa-upload {
  font-size: inherit;
}

.element {
  display: inline-flex;
  align-items: center;
}

i.fa-upload {
  margin: 10px;
  cursor: pointer;
  font-size: 30px;
}

i:hover {
  opacity: 0.6;
}

.custom-file {
  display: none !important;
}

.btn-approve,
.btn-disapprove,
.badge-approve,
.btn-approve:hover,
.btn-disapprove:hover,
.badge-approve:hover {
  color: #f5f6fa;
  font-weight: 500;
}

.btn-disapprove {
  background: #f2796d;
}

.btn-approve,
.badge-approve {
  background: #1fb5cc;
}

.badge-approve {
  border-radius: 90px;
}

.badge-approve i {
  color: #fff !important;
}
</style>